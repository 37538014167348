import {
  faClock,
  faEnvelope,
  faInbox,
  faLocation,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";
export const Footer = () => {
  return (
    <>
      <footer className="container-fluid justify-content-evenly mt-5 row px-3 py-4 gy-4">
        <section className="col-md-4 justify-content-center ms-auto contact_info my-2 col-sm-12">
          <p className="text-white fs-6">
            <span className="text-gold me-2">
              <FontAwesomeIcon icon={faLocation} />
            </span>
            <span className="text-justify">
              {process.env.REACT_APP_ADDRESS1}
              <br /> {process.env.REACT_APP_ADDRESS2},{" "}
              {process.env.REACT_APP_ADDRESS3}, <br />
              {process.env.REACT_APP_CITY} - {process.env.REACT_APP_PINCODE}
            </span>
          </p>
          <p className="text-white fs-5  text-justify fs-6">
            <span className="text-gold me-2">
              <FontAwesomeIcon icon={faPhone} />
            </span>
            <a
              href={`tel: ${process.env.REACT_APP_PHONE}`}
              className="text-decoration-none text-white"
            >
              {" "}
              {process.env.REACT_APP_PHONE}
            </a>
          </p>
          <p className="fs-6  text-justify">
            <span className="text-gold me-2">
              <FontAwesomeIcon icon={faEnvelope} />
            </span>
            <a
              href={`mail: ${process.env.REACT_APP_EMAIL}`}
              className="text-decoration-none text-white"
            >
              {process.env.REACT_APP_EMAIL}
            </a>
          </p>
          <p className="fs-6  text-justify">
            <span className="text-gold me-2">
              <FontAwesomeIcon icon={faClock} />
            </span>
            <span className="text-decoration-none text-white">
              <span className=" fw-bold text-start p-0 m-0">
                Monday - Saturday:{" "}
                <span className="fw-light">9.30 AM to 9.00 PM</span>
              </span>{" "}
              <br />
              <span className="fw-bold text-start p-0 m-0 ms-auto">
                &nbsp;Sunday:
                <span className="fw-light"> 10.00 AM to 12.30 PM</span>
              </span>
            </span>
          </p>
        </section>
        <section className="col-md-4 col-sm-12 pt-lg-4">
          <p className="fs-3 fw-bold text-white text-center">KEEP IN TOUCH</p>
          <p className="fs-6 text-white text-center">
            Subscribe to Our Newsletter to get New Arrivals
          </p>
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Enter email"
              aria-label="Enter email"
              aria-describedby="basic-addon2"
            />
            <span class="input-group-text bg-gold" id="basic-addon2">
              <button className="btn btn-md bg-gold m-0 p-0">Subcribe</button>
            </span>
          </div>
        </section>
        <section className="col-md-4 col-sm-12 pt-lg-5">
          <ul className="list-group">
            <li className="list-group-item border-0 bg-transparent text-light p-2 text-lg-center">
              <Link to={"/"} className="text-decoration-none text-light">
                Home
              </Link>
            </li>
            <li className="list-group-item border-0 bg-transparent text-light p-2 text-lg-center">
              <Link to={"/contact"} className="text-decoration-none text-light">
                {" "}
                Contact Us
              </Link>
            </li>
            <li className="list-group-item border-0 bg-transparent text-light p-2 text-lg-center">
              <Link to={"/about"} className="text-decoration-none text-light">
                About Us
              </Link>
            </li>
          </ul>
        </section>
        <section className="col-12 pt-4">
          <p className="fs-6 text-light text-center">
            &copy; Copyrights 2022. Powered by :{" "}
            <span className="fw-bold">instacloudinc</span>
          </p>
        </section>
      </footer>
    </>
  );
};
