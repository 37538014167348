import Swal from "sweetalert2";
import axios from "axios";
import Cookies from "js-cookie";

// handling add to cart
export const HandleAddToCart = (id) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(process.env.REACT_APP_URL + "addToCart", {
        prodId: id,
        quantity: 100,
        access: Cookies.get("access"),
      },{headers: { 'Content-Type': 'application/json', }})
      .then((response) => {
        if (response.data.status === 200) {
          resolve("success");
        } else if (response.data.status === 401) resolve("login");
        else
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        // setProductData(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const HandleRemoveFromCart = (id) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(process.env.REACT_APP_URL + "removeFromCart", {
        prodId: id,
        access: Cookies.get("access"),
      },{headers: { 'Content-Type': 'application/json', }})
      .then((response) => {
        if (response.data.status === 200) {
          resolve("success");
        } else if (response.data.status === 401) resolve("login");
        else
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: '<a href="">Why do I have this issue?</a>',
          });
        // setProductData(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
// handle add wishlist
export const HandleAddWishlist = (id) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(process.env.REACT_APP_URL + "addToWishlist", {
        prodId: id,
        access: Cookies.get("access"),
      },{headers: { 'Content-Type': 'application/json', }})
      .then((response) => {
        if (response.data.status === 200) {
          resolve("success");
        } else if (response.data.status === 401) resolve("login");
        else
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        // setProductData(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const HandleRemoveWishlist = (id) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(process.env.REACT_APP_URL + "deleteFromWishlist", {
        prodId: id,
        access: Cookies.get("access"),
      },{headers: { 'Content-Type': 'application/json', }})
      .then((response) => {
        if (response.data.status === 200) {
          resolve("success");
        } else if (response.data.status === 401) resolve("login");
        else
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: '<a href="">Why do I have this issue?</a>',
          });
        // setProductData(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// function to get wishlist
export const WishlistData = () => {
  return new Promise(function (resolve, reject) {
    axios
      .post(process.env.REACT_APP_URL + "getWishlistData", {
        access: Cookies.get("access"),
      },{headers: { 'Content-Type': 'application/json', }})
      .then((response) => {
        resolve(response.data.message);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// funtion to get cart products
export const cartData = () => {
  return new Promise(function (resolve, reject) {
    axios
      .post(process.env.REACT_APP_URL + "getCartProducts", {
        access: Cookies.get("access"),
      },{headers: { 'Content-Type': 'application/json', }})
      .then((response) => {
        resolve(response.data.message);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
