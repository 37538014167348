import React from "react";
import { Header, Footer } from "../../components";
export const Contact = () => {
  return (
    <>
      <Header />
      <section className="container my-5">
        <h1 className="text-dark fs-1 fw-bold text-start">
          Contact Information
        </h1>
        <div className="row border p-3 border-success shadow shadow-md row-cols-md-4 row-cols-1 my-4">
          <div className="col">
            <p className="fw-bold fs-5">
              <span className="text-primary fs-4 ">Address: </span>
              <span>
                <br />
                {process.env.REACT_APP_ADDRESS1}
                <br /> {process.env.REACT_APP_ADDRESS2},<br />{" "}
                {process.env.REACT_APP_ADDRESS3}, <br />
                {process.env.REACT_APP_CITY} - {process.env.REACT_APP_PINCODE}
              </span>
            </p>
          </div>
          <div className="col">
            <p className="fw-bold fs-5">
              <span className="text-primary fs-4 ">Mobile: </span>
              <a
                href={`tel: ${process.env.REACT_APP_PHONE}`}
                className="text-decoration-none text-dark"
              >
                {" "}
                {process.env.REACT_APP_PHONE}
              </a>
            </p>
          </div>
          <div className="col">
            <p className="fw-bold fs-5">
              <span className="text-primary fs-4 ">Email: </span>
              <a
                href={`mailto: ${process.env.REACT_APP_EMAIL}`}
                className="text-decoration-none text-dark"
              >
                {process.env.REACT_APP_EMAIL}
              </a>
            </p>
          </div>
          <div className="col">
            <p className="fw-bold fs-5">
              <span className="text-primary fs-4 ">Opening Time: </span> <br />
              <span className="text-decoration-none">
                <span className=" fw-bold text-center text-start p-0 m-0">
                  Monday - Saturday:{" "}
                </span>
                <br />
                <span className="fw-light">9.30 AM to 9.00 PM</span>
                <br />
                <span className="fw-bold text-start p-0 m-0 ms-auto">
                  Sunday:
                </span>
                <br />
                <span className="fw-light"> 10.00 AM to 12.30 PM</span>
              </span>
            </p>
          </div>
        </div>
        <div className="row row-cols-md-2 row-cols-1">
          <div className="col py-5">
            <h2 className="text-start text-dark fw-bold">
              {" "}
              <span className="text-primary">Terms </span>
              and <span className="text-primary">Conditions</span>
            </h2>
            <p className="fs-5 py-3 pe-5 fw-bold text-dark text-justify">
              <span className="fw-bold text-decoration-underline">color:</span>{" "}
              Though we try to achieve perfect color match to the sample , there
              may be unavoidable variation in shades.
            </p>
            <p className="fs-5 py-3 pe-5 fw-bold text-dark text-justify">
              <span className="fw-bold text-decoration-underline">
                No Samples:
              </span>{" "}
              We don't practise business by providing sample cards.
            </p>
            <p className="fs-5 py-3 pe-5 fw-bold text-dark text-justify">
              <span className="fw-bold text-decoration-underline">
                Availability:
              </span>{" "}
              we are the wholesale ready stockist of wedding cards but due to
              customisation and other unavoidable reasons, cards are subject to
              availability
            </p>
          </div>
          <div className="col">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1959.4229427552941!2d78.69752482961175!3d10.823103600000007!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3baaf5fafcc6d9ad%3A0xbb520b1b2a9a8147!2sravi%20cards!5e0!3m2!1sen!2sin!4v1660934996536!5m2!1sen!2sin"
              className="map_view"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
