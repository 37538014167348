import React, { useContext, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Modal, Button } from "react-bootstrap";
import Cookies from "js-cookie";

const Userlogin = (props) => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [pwd, setPwd] = useState("");
  const [openRegisterModal, setOpenResiterModal] = useState(false);
  const [formData, setFormData] = useState([]);
  const [showPwd, setshowPwd] = useState(false);
  const [formValidate, setFormValidate] = useState(false);
  const login = () => {
    if (mobileNumber !== "") {
      axios
        .post(
          process.env.REACT_APP_URL + "userLogin",
          {
            mobile: mobileNumber,
          },
          { headers: { "Content-Type": "application/json" } }
        )
        .then((response) => {
          if (response.data.length === 0) {
            props.setloginModal(false);
            setOpenResiterModal(true);
            HandleFormData("mobile", mobileNumber);
          } else {
            setOpenResiterModal(false);
            setshowPwd(true);
          }
        })
        .catch((err) => {
          Swal.showValidationMessage(`Request failed: ${err}`);
        });
    }
  };

  const HandleFormData = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };

  const HandleSignin = () => {
    if (pwd !== "")
      axios
        .post(
          process.env.REACT_APP_URL + "verifyLogin",
          {
            mob: mobileNumber,
            pwd: pwd,
          },
          { headers: { "Content-Type": "application/json" } }
        )
        .then((res) => {
          if (res.data.status === 200) {
            Cookies.set("access", res.data.access, {
              expires: new Date(new Date().getTime() + 60 * 60 * 1000),
            });
            props.setloginModal(false);
            window.location.reload();
          } else if (res.data.status === 500) {
            alert(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
  };

  const HandleRegister = () => {
    if (
      formData["city"] !== "" &&
      formData["name"] !== "" &&
      formData["mobile"] !== "" &&
      formData["password"] !== ""
    ) {
      setFormValidate(false);
      axios
        .post(
          process.env.REACT_APP_URL + "registerNewUser",
          {
            data: formData,
          },
          { headers: { "Content-Type": "application/json" } }
        )
        .then((response) => {
          if (response.data.status === 200) {
            setOpenResiterModal(false);
            Cookies.set("access", response.data.access, {
              expires: new Date(new Date().getTime() + 60 * 60 * 1000),
            });

            Swal.fire({
              icon: "success",
              title: "Registered Successfully",
              text: "You are successfuly registered with us",
            }).then(() => window.location.reload());
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      Swal.fire({
        icon: "error",
        title: "Missing field",
        text: "Please fill all the mandatory field !",
      });
      setFormValidate(true);
    }
  };

  return (
    <>
      {/* login modal */}
      <Modal
        show={props.openModal}
        onHide={() => {
          setshowPwd(false);
          props.setloginModal(false);
        }}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-bolder fs-3">Login Here</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-start fs-5 m-5">
            <label className="form-label fw-bold fs-5">Mobile Number</label>
            {showPwd ? (
              <>
                <input
                  type="text"
                  id="mobile_number"
                  name="mobile_number"
                  placeholder="Enter your mobile number"
                  className="form-control"
                  value={mobileNumber}
                  readOnly
                />
                <label className="form-label fw-bold fs-6">Password</label>
                <input
                  type="password"
                  id="pwd"
                  name="pwd"
                  placeholder="Enter your password"
                  className="form-control"
                  maxLength={10}
                  onChange={(event) => setPwd(event.target.value)}
                />
                <div className="text-end">
                  <button
                    className="btn btn-md  btn-success mt-3"
                    onClick={() => HandleSignin()}
                  >
                    Signin
                  </button>
                </div>
              </>
            ) : (
              <>
                <input
                  type="text"
                  id="mobile_number"
                  name="mobile_number"
                  placeholder="Enter your mobile number"
                  className="form-control"
                  onChange={(event) => setMobileNumber(event.target.value)}
                />
              </>
            )}
          </div>
          <div className="text-center mb-3">
            {!showPwd ? (
              <Button
                className="py-2 px-4"
                variant="success"
                onClick={() => login()}
              >
                Next Step
              </Button>
            ) : (
              <></>
            )}
          </div>
        </Modal.Body>
      </Modal>
      {/* regiter modal */}
      <Modal
        show={openRegisterModal}
        onHide={() => setOpenResiterModal(false)}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-bolder fs-3">Register Now</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form class="row g-3 p-3">
            <div class="col-12">
              <label for="name" class="form-label fw-bold">
                Name <span className="text-danger fs-5">*</span>
              </label>
              <input
                type="text"
                class="form-control"
                id="name"
                placeholder="Enter your name"
                onChange={(event) =>
                  HandleFormData(event.target.id, event.target.value)
                }
              />
            </div>
            <div class="col-6">
              <label for="mobile" class="form-label fw-bold">
                Mobile number <span className="text-danger fs-5">*</span>
              </label>
              <input
                type="text"
                class="form-control"
                id="mobile"
                placeholder="Enter your mobile number"
                defaultValue={mobileNumber}
                maxLength={10}
                onChange={(event) =>
                  HandleFormData(event.target.id, event.target.value)
                }
                maxlength="10"
              />
            </div>

            <div class="col-md-6">
              <label for="password" class="form-label fw-bold">
                Password <span className="text-danger fs-5">*</span>
              </label>
              <input
                type="password"
                placeholder="*************"
                class="form-control"
                id="password"
                onChange={(event) =>
                  HandleFormData(event.target.id, event.target.value)
                }
              />
            </div>
            <div class="col-md-6">
              <label for="email" class="form-label  fw-bold">
                Email
              </label>
              <input
                type="email"
                class="form-control"
                placeholder="Enter your email address"
                id="email"
                onChange={(event) =>
                  HandleFormData(event.target.id, event.target.value)
                }
              />
            </div>

            <div class="col-md-6">
              <label for="city" class="form-label fw-bold">
                City <span className="text-danger fs-5">*</span>
              </label>
              <input
                type="text"
                placeholder="Enter yout city"
                class="form-control"
                id="city"
                onChange={(event) =>
                  HandleFormData(event.target.id, event.target.value)
                }
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => setOpenResiterModal(false)}>
            Close
          </Button>
          <Button variant="success" onClick={() => HandleRegister()}>
            Register
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Userlogin;
