import "./App.css";
import React, { useEffect, useState } from "react";
import Routefunc from "./routes";
import FloatingIcon from "./components/floatingIcon/floatingIcon";
import { PreloaderContext } from "./context/preloader";
import Preloaderimg from "./assets/images/gif/preloader.gif";
import { loginContext } from "./context/loginContext";
import Userlogin from "./lib/loginFunc";
export default function App() {
  const [preloader, setPreloader] = useState(false);
  const [loginModal, setloginModal] = useState(false);
  return (
    <>
      <div className={preloader ? "preloaderImg" : "d-none"}>
        <img src={Preloaderimg} />
      </div>
      {/* Login modal */}
      <Userlogin
        openModal={loginModal}
        setloginModal={() => setloginModal(false)}
      />
      <PreloaderContext.Provider value={{ preloader, setPreloader }}>
        <loginContext.Provider value={{ loginModal, setloginModal }}>
          <div className={preloader ? "preloader" : ""}>
            <FloatingIcon />
            <Routefunc />
          </div>
        </loginContext.Provider>
      </PreloaderContext.Provider>
    </>
  );
}
