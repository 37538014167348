import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import React from "react";
import "./floatingIcon.css";
const FloatingIcon = () => {
  return (
    <>
      <a
        href={`tel: ${process.env.REACT_APP_PHONE}`}
        className="text-decoration-none bg-info floating_phone"
      >
        <FontAwesomeIcon icon={faPhone} className="float" />
      </a>
      <a
        href={`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_PHONE}&text=Hi%20There!`}
        className="text-decoration-none bg-success floating_whatsapp"
      >
        <FontAwesomeIcon icon={faWhatsapp} className="float" />
      </a>
    </>
  );
};

export default FloatingIcon;
