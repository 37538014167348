import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartPlus,
  faHeart as faHeartBold,
} from "@fortawesome/free-solid-svg-icons";
import { faHeart as faHeartReg } from "@fortawesome/free-regular-svg-icons";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import Swal from "sweetalert2";
import { Footer, Header } from "../../components";
import {
  HandleRemoveWishlist,
  cartData,
  WishlistData,
  HandleAddToCart,
  HandleAddWishlist,
} from "../../components/carousel/HandlingFunc";
import CartBtn from "../../components/cartBtn/cartBtn";
import { Link } from "react-router-dom";
import { PreloaderContext } from "../../context/preloader";
import LargeMenu from "../../components/shortcutField/largeDevice/menu";
import MobileMenu from "../../components/shortcutField/mobile/menu";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { loginContext } from "../../context/loginContext";
import "./productList.css";
import SortOptions from "../../components/sorting/sorting";
import SearchOptions from "../../components/search/search";

const ProductList = () => {
  const { setloginModal } = useContext(loginContext);
  const { height, width } = useWindowDimensions();
  let { category } = useParams();
  const { setPreloader } = useContext(PreloaderContext);
  const [wishlistDataState, setWishlistData] = useState(null);
  const [cartDataState, setCartData] = useState(null);
  const [totalproductList, setTotalProductList] = useState(null);
  const [productList, setProductList] = useState(null);
  const [refreshFlag, setRefreshFlag] = useState(true);
  useEffect(() => {
    getProductList(category);
    getCartData();
    getWishList();
  }, []);

  //get product list by category
  const getProductList = (category) => {
    setPreloader(true);
    axios
      .post(
        process.env.REACT_APP_URL + "getProductList",
        {
          type: category,
        },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((response) => {
        setPreloader(false);
        response = JSON.parse(response.data);
        setProductList(response);
        setTotalProductList(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // getting cart data for this client
  const getCartData = () => {
    setCartData([]);
    cartData().then((res) => {
      if (res !== undefined) {
        res.map((e) =>
          setCartData((cartDataState) => [
            ...cartDataState,
            { prod_id: e["prod_id"], quantity: e["quantity"] },
          ])
        );
      }
    });
  };

  // get wishlist data to show wishlist products
  const getWishList = () => {
    WishlistData().then((res) => {
      if (res !== undefined) setWishlistData(res);
    });
  };

  return (
    <>
      <Header
        WishlistLength={
          wishlistDataState !== null ? wishlistDataState.length : <></>
        }
        cartLength={cartDataState !== null ? cartDataState.length : <></>}
        updateRoute={(val) => {
          getProductList(val);
        }}
      />
      <div className="container-fluid d-flex justify-content-end py-4">
        {productList !== null &&
        productList !== [] &&
        productList !== undefined ? (
          <>
            <SortOptions
              TotalData={productList}
              btnClassName="mx-5 fw-bold fs-5"
              resultData={(data) => {
                setProductList(data);
                setRefreshFlag(!refreshFlag);
              }}
            />
            <SearchOptions
              TotalData={totalproductList}
              InputDivClass="mx-4 border border-secondary"
              resultData={(data) => {
                setProductList(data);
                setRefreshFlag(!refreshFlag);
              }}
            />
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="container-fluid row">
        {parseInt(width) > 992 ? (
          <>
            {" "}
            <div className="col-md-2 col-sm-12">
              <LargeMenu updateRoute={(val) => getProductList(val)} />{" "}
            </div>
          </>
        ) : (
          <>
            <MobileMenu
              updateRoute={(val) => {
                getProductList(val);
              }}
            />
          </>
        )}
        <div className="col-md-10 col-sm-12 product-list-div">
          <div className="row">
            {productList !== null &&
            productList !== [] &&
            productList !== undefined ? (
              productList.map((item) => (
                <>
                  <div className="col-lg-4 col-md-6 col-6 mt-3">
                    <div
                      className="card product_image_card"
                      style={{
                        position:
                          item.stock_status !== "instock" ? "relative" : "",
                      }}
                    >
                      {item.stock_status !== "instock" ? (
                        <p className="position-absolute bottom-0 end-0 corner-ribbon-text bg-danger px-3 text-white rounded py-1">
                          Out of stock
                        </p>
                      ) : (
                        <></>
                      )}
                      {wishlistDataState !== null &&
                      wishlistDataState !== undefined &&
                      wishlistDataState.filter(
                        (data) => data.prod_id === item.id.toString()
                      )[0] ? (
                        <button
                          onClick={() => {
                            Swal.fire({
                              title: "Are you sure?",
                              text: "You want to remove this product from wishlist!",
                              icon: "warning",
                              showCancelButton: true,
                              confirmButtonColor: "#3085d6",
                              cancelButtonColor: "#d33",
                              confirmButtonText: "Yes, remove it!",
                            }).then((result) => {
                              if (result.isConfirmed) {
                                setPreloader(true);
                                HandleRemoveWishlist(item.id.toString()).then(
                                  (res) => {
                                    setPreloader(false);
                                    res === "success" ? getWishList() : <></>;
                                  }
                                );
                              }
                            });
                          }}
                          className="wishlist_btn rounded"
                        >
                          <FontAwesomeIcon
                            icon={faHeartBold}
                            className="heartIcon fs-5"
                          />
                        </button>
                      ) : (
                        <>
                          <button
                            onClick={() => {
                              setPreloader(true);
                              HandleAddWishlist(item.id.toString()).then(
                                (res) => {
                                  setPreloader(false);
                                  res === "success" ? (
                                    getWishList()
                                  ) : res === "login" ? (
                                    setloginModal(true)
                                  ) : (
                                    <></>
                                  );
                                }
                              );
                            }}
                            className="wishlist_btn rounded"
                          >
                            <FontAwesomeIcon
                              icon={faHeartReg}
                              className="heartIcon fs-5"
                            />
                          </button>
                        </>
                      )}
                      <Link
                        to={"/product-page/" + item.id}
                        className="text-decoration-none text-center text-dark"
                      >
                        <div
                          className="product-card-img"
                          style={{
                            backgroundImage: `url(${item.images?.[0]?.["src"]})`,
                          }}
                        />
                      </Link>
                      <div className="card-body">
                        {/* <h5 class="card-title">Card title</h5> */}
                        <Link
                          to={"/product-page/" + item.id}
                          className="text-decoration-none text-dark"
                        >
                          <h6 className="card-text mt-3">
                            <p>
                              <span className="fw-bold">
                                Product Code : {item.id}
                              </span>
                            </p>
                          </h6>
                        </Link>
                        <div className="row">
                          {item.price !== item.regular_price ? (
                            <>
                              <p className="fw-bold col-6 price_text">
                                ₹ <span className="fs-6">{item.price} </span>{" "}
                                <span className="text-danger">
                                  -{" "}
                                  {Math.round(
                                    item.regular_price -
                                      item.regular_price * (item.price / 100)
                                  )}
                                  % <small>You save</small>
                                </span>
                                <br />
                                <span className="text-muted">
                                  <s>M.R.P. {item.regular_price}</s>
                                </span>
                              </p>
                            </>
                          ) : (
                            <p className="fw-bold col-6 price_text">
                              ₹ <span className="fs-6">{item.price}</span>
                            </p>
                          )}
                          {/* Cart btn */}
                          {item.stock_status !== "instock" ? (
                            <></>
                          ) : cartDataState !== undefined &&
                            cartDataState.filter(
                              (data) => data.prod_id === item.id.toString()
                            )[0] ? (
                            <>
                              {/* <Link to="/cart" className="ms-auto"> */}
                              <span className="col-6 ms-auto">
                                <CartBtn
                                  data={
                                    cartDataState[
                                      cartDataState.findIndex(
                                        (data) =>
                                          data.prod_id === item.id.toString()
                                      )
                                    ]
                                  }
                                  updatedData={() => getCartData()}
                                />
                              </span>
                              {/* </Link> */}
                            </>
                          ) : (
                            <>
                              <button
                                onClick={() => {
                                  setPreloader(true);
                                  HandleAddToCart(item.id.toString()).then(
                                    (res) => {
                                      setPreloader(false);
                                      res === "success" ? (
                                        getCartData()
                                      ) : res === "login" ? (
                                        setloginModal(true)
                                      ) : (
                                        <></>
                                      );
                                    }
                                  );
                                }}
                                className=" add_button btn btn-sm text-gold ms-auto"
                              >
                                <FontAwesomeIcon icon={faCartPlus} />{" "}
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))
            ) : (
              <>
                <h5 className="text-center text-dark m-5">Loding...</h5>
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProductList;
