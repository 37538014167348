import React from "react";
import { Link } from "react-router-dom";
import "./menu.css";
const LargeMenu = (props) => {
  return (
    <div className="LargeDeviceList">
      <ul className="list-group text-muted ">
        <li className="list-group-item">
          <h4 className="text-center fw-bold">PRODUCTS</h4>
        </li>
        <li className="list-group-item fs-5">
          <Link
            to="/product-list/all"
            className="text-decoration-none text-nowrap"
            onClick={() => props.updateRoute("all")}
          >
            All
          </Link>
        </li>
        <li className="list-group-item fs-5">
          <div className="dropdown">
            <button
              className="btn  fs-5 text-light p-0 border-0 shadow-none dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Wedding Cards
            </button>
            <ul
              className="dropdown-menu mb-5 border-0 position-relative bg-transparent p-0"
              aria-labelledby="dropdownMenuButton1"
            >
              <li className="p-2">
                <Link
                  to="/product-list/19"
                  className=" dropdown-item text-light fs-6 p-0 text-decoration-none text-nowrap"
                  onClick={() => props.updateRoute("19")}
                >
                  - Hindu Wedding
                </Link>
              </li>
              <li className="p-2">
                <Link
                  to="/product-list/16"
                  className=" dropdown-item text-light fs-6 p-0 text-decoration-none text-nowrap"
                  onClick={() => props.updateRoute("16")}
                >
                  - Christian Wedding
                </Link>
              </li>

              <li className="p-2">
                <Link
                  to="/product-list/21"
                  className=" dropdown-item text-light fs-6 p-0 text-decoration-none text-nowrap"
                  onClick={() => props.updateRoute("21")}
                >
                  - Muslim Wedding
                </Link>
              </li>

              <li className="p-2">
                <Link
                  to="/product-list/28"
                  className=" dropdown-item text-light fs-6 p-0 text-decoration-none text-nowrap"
                  onClick={() => props.updateRoute("28")}
                >
                  - Tamil wedding Cards
                </Link>
              </li>
            </ul>
          </div>
        </li>

        <li className="list-group-item">
          <div className="dropdown">
            <button
              className="btn  fs-5 text-light p-0 border-0 shadow-none dropdown-toggle"
              type="button"
              id="dropdownMenuButton2"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Occasions
            </button>
            <ul
              className="dropdown-menu mb-5 border-0 position-relative bg-transparent p-0"
              aria-labelledby="dropdownMenuButton2"
            >
              <li className="p-2">
                <Link
                  to="/product-list/30"
                  className=" dropdown-item text-light fs-6 p-0 text-decoration-none text-nowrap"
                  onClick={() => props.updateRoute("30")}
                >
                  - Engagement Cards
                </Link>
              </li>
              <li className="p-2">
                <Link
                  to="/product-list/29"
                  className=" dropdown-item text-light fs-6 p-0 text-decoration-none text-nowrap"
                  onClick={() => props.updateRoute("29")}
                >
                  - Luxury Invitations
                </Link>
              </li>
              <li className="p-2">
                <Link
                  to="/product-list/31"
                  className=" dropdown-item text-light fs-6 p-0 text-decoration-none text-nowrap"
                  onClick={() => props.updateRoute("31")}
                >
                  - Seemantham
                </Link>
              </li>
              <li className="p-2">
                <Link
                  to="/product-list/27"
                  className=" dropdown-item text-light fs-6 p-0 text-decoration-none text-nowrap"
                  onClick={() => props.updateRoute("27")}
                >
                  - Personal Cards
                </Link>
              </li>
              <li className="p-2">
                <Link
                  to="/product-list/26"
                  className=" dropdown-item text-light fs-6 p-0 text-decoration-none text-nowrap"
                  onClick={() => props.updateRoute("26")}
                >
                  - Birthday Cards
                </Link>
              </li>
              <li className="p-2">
                <Link
                  to="/product-list/20"
                  className=" dropdown-item text-light fs-6 p-0 text-decoration-none text-nowrap"
                  onClick={() => props.updateRoute("20")}
                >
                  - House Warming
                </Link>
              </li>
              <li className="p-2">
                <Link
                  to="/product-list/17"
                  className=" dropdown-item text-light fs-6 p-0 text-decoration-none text-nowrap"
                  onClick={() => props.updateRoute("17")}
                >
                  - Ear Boring
                </Link>
              </li>
              <li className="p-2">
                <Link
                  to="/product-list/32"
                  className=" dropdown-item text-light fs-6 p-0 text-decoration-none text-nowrap"
                  onClick={() => props.updateRoute("32")}
                >
                  - Baby Shower
                </Link>
              </li>
              <li className="p-2">
                <Link
                  to="/product-list/33"
                  className=" dropdown-item text-light fs-6 p-0 text-decoration-none text-nowrap"
                  onClick={() => props.updateRoute("33")}
                >
                  - Grahapravesham Cards
                </Link>
              </li>
              <li className="p-2">
                <Link
                  to="/product-list/34"
                  className=" dropdown-item text-light fs-6 p-0 text-decoration-none text-nowrap"
                  onClick={() => props.updateRoute("34")}
                >
                  - Puberty Cards
                </Link>
              </li>
              <li className="p-2">
                <Link
                  to="/product-list/35"
                  className=" dropdown-item text-light fs-6 p-0 text-decoration-none text-nowrap"
                  onClick={() => props.updateRoute("35")}
                >
                  - Customised Cards
                </Link>
              </li>
              <li className="p-2">
                <Link
                  to="/product-list/36"
                  className=" dropdown-item text-light fs-6 p-0 text-decoration-none text-nowrap"
                  onClick={() => props.updateRoute("36")}
                >
                  - Holy Communion
                </Link>
              </li>
            </ul>
          </div>
        </li>

        <li className="list-group-item">
          <div className="dropdown">
            <button
              className="btn  fs-5 text-light p-0 border-0 shadow-none dropdown-toggle"
              type="button"
              id="dropdownMenuButton3"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Wedding Return Gifts
            </button>
            <ul
              className="dropdown-menu mb-5 border-0 position-relative bg-transparent p-0"
              aria-labelledby="dropdownMenuButton3"
            >
              <li className="p-2">
                <Link
                  to="/product-list/37"
                  className=" dropdown-item text-light fs-6 p-0 text-decoration-none text-nowrap"
                  onClick={() => props.updateRoute("37")}
                >
                  - Home made chocolates
                </Link>
              </li>
            </ul>
          </div>
        </li>

        <li className="list-group-item fs-5">
          <Link
            to="/product-list/23"
            className="text-decoration-none text-nowrap"
            onClick={() => props.updateRoute("23")}
          >
            All Occasions
          </Link>
        </li>
        <li className="list-group-item fs-5">
          <Link
            to="/product-list/22"
            className="text-decoration-none text-nowrap"
            onClick={() => props.updateRoute("22")}
          >
            New Arrivals
          </Link>
        </li>
        <li className="list-group-item fs-5">
          <Link
            to="/product-list/24"
            className="text-decoration-none text-nowrap"
            onClick={() => props.updateRoute("24")}
          >
            Others
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default LargeMenu;
