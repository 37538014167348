import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Header, Footer } from "../../components";
import "./wishlist.css";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faCartPlus,
  faHeart as faHeartBold,
} from "@fortawesome/free-solid-svg-icons";
import {
  HandleRemoveWishlist,
  cartData,
  HandleAddToCart,
} from "../../components/carousel/HandlingFunc";
import { Link } from "react-router-dom";
import CartBtn from "../../components/cartBtn/cartBtn";
import { PreloaderContext } from "../../context/preloader";
import { loginContext } from "../../context/loginContext";
import Cookies from "js-cookie";

const Wishlist = () => {
  const { setloginModal } = useContext(loginContext);
  const { setPreloader } = useContext(PreloaderContext);
  const [wishlistDataState, setWishlistData] = useState(null);
  const [cartDataState, setCartData] = useState([]);

  const getWishList = () => {
    setPreloader(true);
    axios
      .post(
        process.env.REACT_APP_URL + "getWishlistWithProd",
        {
          access: Cookies.get("access"),
        },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((response) => {
        if (response.data.status === 200) {
          setPreloader(false);
          setWishlistData(response.data.message);
        } else if (response.data.status === 401) {
          setloginModal(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // getting cart data for this client
  const getCartData = () => {
    setPreloader(true);
    setCartData([]);
    cartData().then((res) => {
      setPreloader(false);
      res.map((e) =>
        setCartData((cartDataState) => [
          ...cartDataState,
          { prod_id: e["prod_id"], quantity: e["quantity"] },
        ])
      );
    });
  };

  useEffect(() => {
    getWishList();
    getCartData();
  }, []);

  return (
    <>
      <Header
        WishlistLength={
          wishlistDataState !== null ? wishlistDataState.length : 0
        }
        cartLength={cartDataState !== [] ? cartDataState.length : 0}
      />
      <div className="container-fluid d-flex justify-content-between bg-primaryBlue">
        <h1 className="text-center fw-bold border-0 p-3 text-gold my-3">
          Your Wishlist
        </h1>
        <div className="m-4">
          <Link
            to="/product-list/all"
            className="btn text-decoration-none btn-md p-2 btn-outline-secondary text-nowrap text-white"
          >
            Continue Shopping &nbsp;
            <FontAwesomeIcon icon={faArrowRight} />
          </Link>
        </div>
      </div>
      <div
        className="row justify-content-start container-fluid wishlist_div"
        style={{ float: "center" }}
      >
        {wishlistDataState !== null ? (
          wishlistDataState.length === 0 ? (
            <h3 className="container-fluid text-center text-dark my-5">
              No item added to wishlist{" "}
              <Link to="/" className="text-decoration-none text-gold">
                Continue shopping
              </Link>
            </h3>
          ) : (
            wishlistDataState.map((item) => (
              <>
                <div className="col-lg-3 col-md-4 mt-3 col-sm-12">
                  <div
                    className="card m-4 product_image_card"
                    style={{
                      position:
                        item.stock_status !== "instock" ? "relative" : "",
                    }}
                  >
                    {item.stock_status !== "instock" ? (
                      <p className="position-absolute bottom-0 end-0 corner-ribbon-text bg-danger px-3 text-white rounded py-1">
                        Out of stock
                      </p>
                    ) : (
                      <></>
                    )}
                    <button
                      onClick={() => {
                        Swal.fire({
                          title: "Are you sure?",
                          text: "You want to remove this product from wishlist!",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          confirmButtonText: "Yes, remove it!",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            HandleRemoveWishlist(item.id).then((res) => {
                              res === "success" ? getWishList() : <></>;
                            });
                          }
                        });
                      }}
                      className="wishlist_btn rounded"
                    >
                      <FontAwesomeIcon
                        icon={faHeartBold}
                        className="heartIcon fs-5"
                      />
                    </button>
                    <Link
                      to={"/product-page/" + item.id}
                      className="text-decoration-none text-center text-dark"
                    >
                      <div
                        className="product-card-img"
                        style={{
                          backgroundImage: `url(${item.images[0]["src"]})`,
                        }}
                      />
                    </Link>
                    <div className="card-body ">
                      {/* <h5 class="card-title">Card title</h5> */}
                      <Link
                        to={"/product-page/" + item.id}
                        className="text-decoration-none text-dark"
                      >
                        <h6 className="card-text mt-3">
                          <p className="">
                            <span className="fw-bold">
                              Product Code : {item.id}
                            </span>
                          </p>
                        </h6>
                      </Link>
                      <div className="row">
                        {item.price !== item.regular_price ? (
                          <>
                            <p className="col-6 fw-bold price_text">
                              ₹ <span className="fs-5">{item.price} </span>{" "}
                              <span className="text-danger">
                                -{" "}
                                {Math.round(
                                  item.regular_price -
                                    item.regular_price * (item.price / 100)
                                )}
                                % <small>You save</small>
                              </span>
                              <br />
                              <span className="text-muted">
                                <s>M.R.P. {item.regular_price}</s>
                              </span>
                            </p>
                          </>
                        ) : (
                          <p className="fw-bold col-6 price_text mt-3">
                            ₹ <span className="fs-5">{item.price}</span>
                          </p>
                        )}
                        {/* Cart btn */}
                        {item.stock_status !== "instock" ? (
                          <></>
                        ) : cartDataState !== undefined &&
                          cartDataState.filter(
                            (data) => data.prod_id === item.id.toString()
                          )[0] ? (
                          <>
                            {/* <Link to="/cart" className="ms-auto"> */}
                            <span className="col-6 ms-auto">
                              <CartBtn
                                data={
                                  cartDataState[
                                    cartDataState.findIndex(
                                      (data) =>
                                        data.prod_id === item.id.toString()
                                    )
                                  ]
                                }
                                updatedData={() => getCartData()}
                              />
                            </span>
                            {/* </Link> */}
                          </>
                        ) : (
                          <>
                            <button
                              onClick={() =>
                                HandleAddToCart(item.id).then((res) => {
                                  res === "success" ? (
                                    getCartData()
                                  ) : res === "login" ? (
                                    setloginModal(true)
                                  ) : (
                                    <></>
                                  );
                                })
                              }
                              className=" add_button btn btn-sm text-gold ms-auto"
                            >
                              <FontAwesomeIcon icon={faCartPlus} />
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))
          )
        ) : (
          <>
            <h5 className="text-center text-dark container-fluid my-5">
              Loading...
            </h5>
          </>
        )}
      </div>
      <section className="mt-5"> </section>
    </>
  );
};

export default Wishlist;
