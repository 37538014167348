import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { search } from "./searchFunctions";

const SearchOptions = ({ InputDivClass, InputClassName, TotalData, ...props }) => {
  const [searchInput, setSearchInput] = useState("");
  return (
    <div class={`input-group w-25 ${InputDivClass}`}>
      <input type="text" placeholder="Search here...." onInput={(event) => setSearchInput(event.target.value)} className={`form-control ${InputClassName}`} />
      <button
        onClick={() => props.resultData(search(TotalData, searchInput))}
        class="input-group-text bg-transparent"
        type='button'
        disabled={searchInput === '' || false}
      >
        <FontAwesomeIcon icon={faSearch} className="fs-6" />
      </button>
    </div>
  );
};
export default SearchOptions;
