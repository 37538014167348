import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import "./cartBtn.css";
import Cookies from "js-cookie";
import { PreloaderContext } from "../../context/preloader";

const CartBtn = (props) => {
  const { setPreloader } = useContext(PreloaderContext);
  const [inputValue, setInputValue] = useState(null);
  useEffect(() => {
    setInputValue(props.data !== undefined ? props.data.quantity : 100);
  }, [props]);
  const handleCount = (quantity, prod_id) => {
    setPreloader(true);
    axios
      .post(
        process.env.REACT_APP_URL + "updateCartQuan",
        {
          prod_id: prod_id,
          quantity: quantity,
          access: Cookies.get("access"),
        },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((response) => {
        setPreloader(false);
        props.updatedData(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleInput = (event, prodId) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      setInputValue(event.target.value);
      handleCount(event.target.value, prodId);
    }
  };
  const deleteCart = (prod_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to remove this product from Cart!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, remove it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(
            process.env.REACT_APP_URL + "removeFromCart",
            {
              prodId: prod_id,
              access: Cookies.get("access"),
            },
            { headers: { "Content-Type": "application/json" } }
          )
          .then((response) => {
            response.data.status === 200 ? props.updatedData(true) : <></>;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };
  return (
    <>
      <div
        className="btn-group border border-dark shadow shadow-sm"
        role="group"
        aria-label="Basic example"
        style={{ width: "9rem" }}
      >
        {props.data !== undefined && props.data.quantity === "100" ? (
          <button
            type="button"
            style={{ fontSize: "13px" }}
            className="btn btn-white chngBtn fw-bold p-2 shadow-none"
            onClick={() => {
              deleteCart(props.data !== undefined ? props.data.prod_id : "");
              props.updatedData(true);
            }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        ) : (
          <button
            type="button"
            onClick={() => {
              handleCount(
                parseInt(props.data !== undefined ? props.data.quantity : 100) -
                  1,
                props.data !== undefined ? props.data.prod_id : ""
              );
              props.updatedData(true);
            }}
            className="btn btn-white chngBtn fw-bold fs-5 shadow-none p-2"
          >
            -
          </button>
        )}

        <button type="button" className="btn btn-white shadow-none">
          <input
            type="text"
            className="form-control border-0 shadow-none fw-bold"
            value={inputValue !== null ? inputValue : 100}
            onChange={(event) => setInputValue(event.target.value)}
            onBlur={(event) => {
              parseInt(event.target.value) > 99 ? (
                handleInput(
                  event,
                  props.data !== undefined ? props.data.prod_id : ""
                )
              ) : (
                <></>
              );
              props.updatedData(true);
            }}
          />
          {/* <p className="fw-bold fs-5 text-center">{count}</p> */}
        </button>
        <button
          type="button"
          onClick={() => {
            handleCount(
              parseInt(props.data !== undefined ? props.data.quantity : 100) +
                1,
              props.data !== undefined ? props.data.prod_id : ""
            );
            props.updatedData(true);
          }}
          className="btn btn-white chngBtn fw-bold fs-5 shadow-none p-2"
        >
          +
        </button>
      </div>
    </>
  );
};

export default CartBtn;
