import React from "react";
import { Routes, Route } from "react-router-dom";
import { Home, Contact, About } from "../pages";
import Wishlist from "../pages/wishlist/wishlist";
import Cart from "../pages/cart/cart";
import ProductList from "../pages/product_list/productList";
import ProductPage from "../pages/product_page/productPage";
export default function Routefunc() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/wishlist" element={<Wishlist />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/product-list/:category" element={<ProductList />} />
        <Route path="/product-page/:product_id" element={<ProductPage />} />
      </Routes>
    </>
  );
}
