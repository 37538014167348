import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./menu.css";

const MobileMenu = (props) => {
  return (
    <div className="bg-primary container-fluid px-0">
      <nav className="navbar navbar-dark ms-3 bg-primary">
        {/* <div className="container-fluid"> */}
        <button
          className="navbar-toggler"
          type="button"
          id="toggler"
          data-bs-toggle="collapse"
          data-bs-target="#navbarToggleExternalContent"
          aria-controls="navbarToggleExternalContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        {/* </div> */}
      </nav>
      <ul
        className="container-fluid collapse mobileMenuList list-group text-muted"
        id="navbarToggleExternalContent"
        style={{ position: "absolute", zIndex: "4" }}
      >
        <li className="list-group-item">
          <Link
            to="/product-list/all"
            className="text-decoration-none"
            onClick={() => {
              props.updateRoute("all");
              document.getElementById("toggler").click();
            }}
          >
            All
          </Link>
        </li>
        <li
          className="navbar-toggler list-group-item text-light border-0"
          id="toggler"
          data-bs-toggle="collapse"
          data-bs-target="#navbarToggleExternalContent1"
          aria-controls="navbarToggleExternalContent1"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          Wedding Cards <i class="bi bi-caret-down-fill"></i>
        </li>
        <ul
          className="container-fluid collapse list-group text-muted"
          id="navbarToggleExternalContent1"
          style={{ position: "relative", zIndex: "4" }}
        >
          <li className="list-group-item ps-5">
            <Link
              to="/product-list/19"
              className="text-decoration-none"
              onClick={() => {
                props.updateRoute("19");
                document.getElementById("toggler").click();
              }}
            >
              - Hindu Wedding
            </Link>
          </li>
          <li className="list-group-item ps-5">
            <Link
              to="/product-list/16"
              className="text-decoration-none"
              onClick={() => {
                props.updateRoute("16");
                document.getElementById("toggler").click();
              }}
            >
              - Christian Wedding
            </Link>
          </li>
          <li className="list-group-item ps-5">
            <Link
              to="/product-list/21"
              className="text-decoration-none"
              onClick={() => {
                props.updateRoute("21");
                document.getElementById("toggler").click();
              }}
            >
              - Muslim Wedding
            </Link>
          </li>
          <li className="list-group-item ps-5">
            <Link
              to="/product-list/27"
              className="text-decoration-none"
              onClick={() => {
                props.updateRoute("27");
                document.getElementById("toggler").click();
              }}
            >
              - Personal Cards
            </Link>
          </li>
          <li className="list-group-item ps-5">
            <Link
              to="/product-list/28"
              className="text-decoration-none"
              onClick={() => {
                props.updateRoute("28");
                document.getElementById("toggler").click();
              }}
            >
              - Tamil wedding Cards
            </Link>
          </li>
          <li className="list-group-item ps-5">
            <Link
              to="/product-list/29"
              className="text-decoration-none"
              onClick={() => {
                props.updateRoute("29");
                document.getElementById("toggler").click();
              }}
            >
              - Luxury Invitations
            </Link>
          </li>
        </ul>

        <li
          className="navbar-toggler list-group-item text-light border-0"
          id="toggler"
          data-bs-toggle="collapse"
          data-bs-target="#navbarToggleExternalContent2"
          aria-controls="navbarToggleExternalContent2"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          Occasions <i class="bi bi-caret-down-fill"></i>
        </li>
        <ul
          className="container-fluid collapse list-group text-muted"
          id="navbarToggleExternalContent2"
          style={{ position: "relative", zIndex: "4" }}
        >
          <li className="list-group-item">
            <Link
              to="/product-list/30"
              className="text-decoration-none"
              onClick={() => {
                props.updateRoute("30");
                document.getElementById("toggler").click();
              }}
            >
              - Engagement Cards
            </Link>
          </li>
          <li className="list-group-item">
            <Link
              to="/product-list/31"
              className="text-decoration-none"
              onClick={() => {
                props.updateRoute("31");
                document.getElementById("toggler").click();
              }}
            >
              - Seemantham
            </Link>
          </li>
          <li className="list-group-item">
            <Link
              to="/product-list/26"
              className="text-decoration-none"
              onClick={() => {
                props.updateRoute("26");
                document.getElementById("toggler").click();
              }}
            >
              - Birthday Cards
            </Link>
          </li>
          <li className="list-group-item">
            <Link
              to="/product-list/20"
              className="text-decoration-none"
              onClick={() => {
                props.updateRoute("20");
                document.getElementById("toggler").click();
              }}
            >
              - House Warming
            </Link>
          </li>
          <li className="list-group-item">
            <Link
              to="/product-list/17"
              className="text-decoration-none"
              onClick={() => {
                props.updateRoute("17");
                document.getElementById("toggler").click();
              }}
            >
              - Ear Boring
            </Link>
          </li>
          <li className="list-group-item">
            <Link
              to="/product-list/32"
              className="text-decoration-none"
              onClick={() => {
                props.updateRoute("32");
                document.getElementById("toggler").click();
              }}
            >
              - Baby Shower
            </Link>
          </li>
          <li className="list-group-item">
            <Link
              to="/product-list/33"
              className="text-decoration-none"
              onClick={() => {
                props.updateRoute("33");
                document.getElementById("toggler").click();
              }}
            >
              - Grahapravesham Cards
            </Link>
          </li>
          <li className="list-group-item">
            <Link
              to="/product-list/34"
              className="text-decoration-none"
              onClick={() => {
                props.updateRoute("34");
                document.getElementById("toggler").click();
              }}
            >
              - Puberty Cards
            </Link>
          </li>
          <li className="list-group-item">
            <Link
              to="/product-list/35"
              className="text-decoration-none"
              onClick={() => {
                props.updateRoute("35");
                document.getElementById("toggler").click();
              }}
            >
              - Customised Cards
            </Link>
          </li>
          <li className="list-group-item">
            <Link
              to="/product-list/36"
              className="text-decoration-none"
              onClick={() => {
                props.updateRoute("36");
                document.getElementById("toggler").click();
              }}
            >
              - Holy Communion
            </Link>
          </li>
        </ul>
        <li
          className="navbar-toggler list-group-item text-light border-0"
          id="toggler"
          data-bs-toggle="collapse"
          data-bs-target="#navbarToggleExternalContent3"
          aria-controls="navbarToggleExternalContent3"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          Wedding Return Gifts <i class="bi bi-caret-down-fill"></i>
        </li>
        <ul
          className="container-fluid collapse list-group text-muted"
          id="navbarToggleExternalContent3"
          style={{ position: "relative", zIndex: "4" }}
        >
          <li className="list-group-item">
            <Link
              to="/product-list/all"
              className="text-decoration-none"
              onClick={() => {
                props.updateRoute("all");
                document.getElementById("toggler").click();
              }}
            >
              All
            </Link>
          </li>
        </ul>

        <li className="list-group-item">
          <Link
            to="/product-list/23"
            className="text-decoration-none"
            onClick={() => {
              props.updateRoute("23");
              document.getElementById("toggler").click();
            }}
          >
            All Occasions
          </Link>
        </li>
        <li className="list-group-item">
          <Link
            to="/product-list/22"
            className="text-decoration-none"
            onClick={() => {
              props.updateRoute("22");
              document.getElementById("toggler").click();
            }}
          >
            New Arrivals
          </Link>
        </li>
        <li className="list-group-item">
          <Link
            to="/product-list/24"
            className="text-decoration-none"
            onClick={() => {
              props.updateRoute("24");
              document.getElementById("toggler").click();
            }}
          >
            Others
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default MobileMenu;
