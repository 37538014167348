import React, { useContext } from "react";
import "../../lib/style.css";
import "react-multi-carousel/lib/styles.css";
import "./carousel.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartPlus,
  faHeart as faHeartBold,
} from "@fortawesome/free-solid-svg-icons";
import { faHeart as faHeartReg } from "@fortawesome/free-regular-svg-icons";
import {
  HandleAddWishlist,
  HandleRemoveWishlist,
  HandleAddToCart,
} from "./HandlingFunc";
import { Link } from "react-router-dom";
import CartBtn from "../cartBtn/cartBtn";
import { loginContext } from "../../context/loginContext";
import { PreloaderContext } from "../../context/preloader";

export const CarouselFunc = (props) => {
  const { setPreloader } = useContext(PreloaderContext);
  const { setloginModal } = useContext(loginContext);

  //refresh page
  const updateFunc = () => {
    props.updated();
  };

  return (
    <>
      {/* preloader */}
      <div className=" container-fluid row">
        {props.data === undefined && props?.data === [] ? (
          <></>
        ) : (
          props.data.map((item, index) => (
            <>
              {index < 6 ? (
                <div className="col-lg-4 col-md-4 col-6 mt-3 p-1">
                  <div
                    className="card product_image_card"
                    style={{
                      position:
                        item.stock_status === "instock" ? "" : "relative",
                    }}
                  >
                    {item.stock_status === "instock" ? (
                      <></>
                    ) : (
                      <p className="position-absolute bottom-0 end-0 corner-ribbon-text bg-danger px-3 text-white rounded py-1">
                        Out of stock
                      </p>
                    )}
                    {/* wishlist btn */}
                    {props.wishlistDataState !== null &&
                    props?.wishlistDataState !== [] &&
                    props.wishlistDataState.filter(
                      (data) => data.prod_id === item.id.toString()
                    )[0] ? (
                      <>
                        <button
                          onClick={() => {
                            setPreloader(true);
                            HandleRemoveWishlist(item.id.toString()).then(
                              (res) => {
                                setPreloader(false);
                                if (res === "success") {
                                  updateFunc();
                                } else if (res === "login") setloginModal(true);
                              }
                            );
                          }}
                          className="wishlist_btn rounded"
                        >
                          <FontAwesomeIcon
                            icon={faHeartBold}
                            className="heartIcon"
                          />
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          onClick={() => {
                            setPreloader(true);
                            HandleAddWishlist(item.id.toString()).then(
                              (res) => {
                                setPreloader(false);
                                if (res === "success") {
                                  updateFunc();
                                } else if (res === "login") setloginModal(true);
                              }
                            );
                          }}
                          className="wishlist_btn rounded"
                        >
                          <FontAwesomeIcon
                            icon={faHeartReg}
                            className="heartIcon"
                          />
                        </button>
                      </>
                    )}
                    <Link
                      to={"/product-page/" + item.id}
                      className="text-decoration-none text-center text-dark"
                    >
                      <div
                        className="product-card-img"
                        style={{
                          backgroundImage: `url(${item.images[0]["src"]})`,
                        }}
                      />
                    </Link>
                    <div className="card-body">
                      <Link
                        to={"/product-page/" + item.id}
                        className="text-decoration-none text-dark"
                      >
                        <h6 className="card-text mt-3">
                          <p className="">
                            <span className="fw-bold">
                              Product Code : {item.id}
                            </span>
                          </p>
                        </h6>
                      </Link>
                      <div className="row">
                        {item.price !== item.regular_price ? (
                          <>
                            <p className="fw-bold col-6 price_text">
                              ₹ <span className="fs-5-lg">{item.price} </span>{" "}
                              <span className="text-danger">
                                -{" "}
                                {Math.round(
                                  item.regular_price -
                                    item.regular_price * (item.price / 100)
                                )}
                                % <small>You save</small>
                              </span>
                              <br />
                              <span className="text-muted">
                                <s>M.R.P. {item.regular_price}</s>
                              </span>
                            </p>
                          </>
                        ) : (
                          <p className="fw-bold col-6 price_text mt-3">
                            ₹ <span className="fs-5">{item.price}</span>
                          </p>
                        )}

                        {/* Cart btn */}
                        {item.stock_status !== "instock" ? (
                          <></>
                        ) : props.cartDataState !== undefined &&
                          props.cartDataState.filter(
                            (data) => data.prod_id === item.id.toString()
                          )[0] ? (
                          <>
                            <span className="col-6 ms-auto">
                              <CartBtn
                                data={
                                  props.cartDataState[
                                    props.cartDataState.findIndex(
                                      (data) =>
                                        data.prod_id === item.id.toString()
                                    )
                                  ]
                                }
                                updatedData={() => {
                                  updateFunc();
                                }}
                              />
                            </span>
                          </>
                        ) : (
                          <>
                            <button
                              onClick={() => {
                                setPreloader(true);
                                HandleAddToCart(item.id).then((res) => {
                                  setPreloader(false);
                                  if (res === "success") {
                                    updateFunc();
                                  } else if (res === "login")
                                    setloginModal(true);
                                });
                              }}
                              className=" add_button col-6 btn fs-6 btn-sm text-gold ms-auto"
                            >
                              <FontAwesomeIcon icon={faCartPlus} />
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </>
          ))
        )}
      </div>
    </>
  );
};
