export const btnList = [
  {
    name: "Marriage",
    value: "18",
  },
  {
    name: "Occasions",
    value: "23",
  },
  {
    name: "Home made chocolates",
    value: "37",
  },
  {
    name: "New Arrivals",
    value: "22",
  },
];
