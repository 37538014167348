import React, { useContext, useEffect, useState } from "react";
import "./home.css";
import { Header, Footer, CarouselFunc } from "../../components";
import { btnList } from "../../array/homeBtnList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { Link } from "react-router-dom";
import { PreloaderContext } from "../../context/preloader";
import { cartData, WishlistData } from "../../components/carousel/HandlingFunc";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

export const Home = (props) => {
  const { setPreloader } = useContext(PreloaderContext);
  const [productData, setProductData] = useState(null);
  const [wishlistLength, setWishlistLength] = useState(null);
  const [cartLength, setCartLength] = useState(null);
  const [wishlistDataState, setWishlistData] = useState(null);
  const [cartDataState, setCartData] = useState([]);

  const fadeImages = [
    {
      url: `${process.env.REACT_APP_URL}uploads/running_banner/banner1.jpg`,
    },
    {
      url: `${process.env.REACT_APP_URL}uploads/running_banner/banner2.jpg`,
    },
    {
      url: `${process.env.REACT_APP_URL}/uploads/running_banner/banner3.jpg`,
    },
  ];

  useEffect(() => {
    getAllProducts();
    getCartData();
    getWishList();
  }, []);
  const getAllProducts = () => {
    setPreloader(true);
    axios
      .post(process.env.REACT_APP_URL + "getAllProducts")
      .then(
        (response) => {
          response = JSON.parse(response.data);
          console.log("res", response);
          setProductData(response);
          setPreloader(false);
        },
        { headers: { "Content-Type": "application/json" } }
      )
      .catch((err) => {
        console.log(err);
      });
  };
  // getting cart data for this client
  const getCartData = () => {
    setCartData([]);
    cartData().then((res) => {
      if (res !== undefined) {
        setCartLength(res.length);
        res.map((e) =>
          setCartData((cartDataState) => [
            ...cartDataState,
            { prod_id: e["prod_id"], quantity: e["quantity"] },
          ])
        );
      }
    });
  };

  // getting wishlist data for this client
  const getWishList = () => {
    WishlistData().then((res) => {
      if (res !== undefined) {
        setWishlistLength(res.length);
        setWishlistData(res);
      }
    });
  };
  const [activeButton, setActiveButton] = useState();
  const HandleCatClick = (event) => {
    setActiveButton(event.target.id);
  };
  return (
    <>
      <Header cartLength={cartLength} WishlistLength={wishlistLength} />
      <div className="slide-container banner m-0">
        <Fade arrows={false} autoplay={true} infinite={true} duration={1100}>
          {fadeImages.map((fadeImage, index) => (
            <div className="each-fade" key={index}>
              <div className="image-container">
                <div
                  className="fade-banner"
                  style={{ backgroundImage: `url(${fadeImage.url})` }}
                />
                {/* <img src={fadeImage.url} alt="banner" /> */}
              </div>
              <h2>{fadeImage.caption}</h2>
            </div>
          ))}
        </Fade>
      </div>
      {/* section A starts  */}
      {productData !== null &&
      wishlistDataState !== null &&
      cartDataState !== [] ? (
        <section className="container p-0">
          <div className="row row-cols-md-2 row-cols-1 my-3 ">
            <div className="col">
              {" "}
              <h3 className="text-md-start text-center home_page_cat_title text-nowrap text-primaryBlue">
                Top in Marriage invitations
              </h3>
            </div>
            <div className="col text-md-end text-center">
              <Link
                to="/product-list/18"
                className="text-decoration-none  view-more-btn shadow shadow-md h6 border border-dark py-2 px-3 fw-bold float-md-end btn btn-md bg-gold"
              >
                View more &nbsp;
                <FontAwesomeIcon icon={faArrowRight} />
              </Link>
            </div>
          </div>
          {/* sending data one time to carousel file */}

          <CarouselFunc
            wishlistDataState={wishlistDataState}
            cartDataState={cartDataState}
            data={productData.filter(
              (filter) =>
                filter.categories.filter((data) => data.id === 16).length !== 0
            )}
            updated={() => {
              getAllProducts();
              getWishList();
              getCartData();
            }}
            getLength={true}
          />
        </section>
      ) : null}
      {/* section A ends */}

      {/* section B starts */}
      {productData !== null &&
      productData.filter(
        (filter) =>
          filter.categories.filter((data) => data.id === 22).length !== 0
      ) ? (
        <section className="container p-0">
          <div className="row row-cols-md-2 row-cols-1 my-3">
            <div className="col">
              {" "}
              <h1 className="text-md-start text-center home_page_cat_title  text-nowrap text-nowrap text-primaryBlue">
                New Arrivals
              </h1>
            </div>
            <div className="col  text-md-end text-center">
              <Link
                to="/product-list/22"
                className="text-decoration-none shadow view-more-btn shadow-md h6 border border-dark py-2 px-3 fw-bold float-md-end btn btn-md bg-gold"
              >
                View more &nbsp;
                <FontAwesomeIcon icon={faArrowRight} />
              </Link>
            </div>
          </div>
          <CarouselFunc
            wishlistDataState={wishlistDataState}
            cartDataState={cartDataState}
            data={productData.filter(
              (filter) =>
                filter.categories.filter((data) => data.id === 22).length !== 0
            )}
            updated={() => {
              getAllProducts();
              getWishList();
              getCartData();
            }}
          />
        </section>
      ) : null}
      {/* section B ends */}

      {/* middle banner starts */}

      <div className="banner_middle mt-5">
        <img
          src={
            process.env.REACT_APP_URL +
            "uploads/running_banner/middle_banner.jpg"
          }
          alt=""
        />
      </div>

      {/* middle banner ends */}
      {/* Category btn starts */}
      <h2 className="text-center text-primaryBlue fw-bolder mt-5">
        Our Products
      </h2>
      <section className="container-fluid justify-content-evenly my-4 py-4 px-2 row">
        {btnList.map((data, index) => (
          <Link
            to={"/product-list/" + data.value}
            id={index}
            className={`btn btn-lg col-md-3 col-sm-12 CatBtn m-3 py-3 text-nowrap ${
              activeButton === index ? "btnActive" : null
            }`}
            value={data.value}
            onClick={(event) => HandleCatClick(event)}
          >
            {data.name}
          </Link>
        ))}
      </section>
      {/* Category btsn ends */}
      <hr />

      {/* section c starts */}
      {productData !== null &&
      productData.filter(
        (filter) =>
          filter.categories.filter((data) => data.id === 22).length !== 0
      ) ? (
        <section className="container p-0">
          <div className="row row-cols-md-2 row-cols-1 my-3">
            <div className="col">
              {" "}
              <h1 className="text-md-start text-center home_page_cat_title text-nowrap text-primaryBlue">
                Top in Hindu invitations
              </h1>
            </div>
            <div className="col text-md-end text-center">
              <Link
                to="/product-list/19"
                className=" text-decoration-none view-more-btn  shadow shadow-md border border-dark py-2 px-3 fw-bold float-md-end btn btn-md bg-gold"
              >
                View more &nbsp;
                <FontAwesomeIcon icon={faArrowRight} />
              </Link>
            </div>
          </div>
          <CarouselFunc
            wishlistDataState={wishlistDataState}
            cartDataState={cartDataState}
            data={productData.filter(
              (filter) =>
                filter.categories.filter((data) => data.id === 22).length !== 0
            )}
            updated={() => {
              getAllProducts();
              getWishList();
              getCartData();
            }}
          />
        </section>
      ) : (
        <></>
      )}
      {/* section c ends */}
      <hr />

      {/* section D starts */}
      {productData !== null &&
      productData.filter(
        (filter) =>
          filter.categories.filter((data) => data.id === 18).length !== 0
      ) ? (
        <section className="container p-0">
          <div className="row row-cols-md-2 row-cols-1 my-3">
            <div className="col">
              {" "}
              <h1 className="text-md-start text-center home_page_cat_title  text-nowrap text-primaryBlue">
                Top in Christian invitations
              </h1>
            </div>
            <div className="col text-md-end text-center">
              <Link
                to="/product-list/16"
                className="text-decoration-none view-more-btn  shadow shadow-md h6 border border-dark py-2 px-3 fw-bold float-md-end btn btn-md bg-gold"
              >
                View more &nbsp;
                <FontAwesomeIcon icon={faArrowRight} />
              </Link>
            </div>
          </div>
          <CarouselFunc
            wishlistDataState={wishlistDataState}
            cartDataState={cartDataState}
            data={productData.filter(
              (filter) =>
                filter.categories.filter((data) => data.id === 18).length !== 0
            )}
            updated={() => {
              getAllProducts();
              getWishList();
              getCartData();
            }}
          />
        </section>
      ) : (
        <></>
      )}
      <hr />
      {/* section D ends */}

      {/* section E starts */}
      {productData !== null &&
      productData.filter(
        (filter) =>
          filter.categories.filter((data) => data.id === 17).length !== 0
      ) ? (
        <section className="container p-0">
          <div className="row row-cols-md-2 row-cols-1 my-3">
            <div className="col">
              {" "}
              <h1 className="text-md-start text-center home_page_cat_title  text-nowrap text-primaryBlue">
                Top in Muslium invitations
              </h1>
            </div>
            <div className="col text-md-end text-center">
              <Link
                to="/product-list/21"
                className="text-decoration-none view-more-btn  shadow shadow-md h6 border border-dark py-2 px-3 fw-bold float-md-end btn btn-md bg-gold"
              >
                View more &nbsp;
                <FontAwesomeIcon icon={faArrowRight} />
              </Link>
            </div>
          </div>
          <CarouselFunc
            wishlistDataState={wishlistDataState}
            cartDataState={cartDataState}
            data={productData.filter(
              (filter) =>
                filter.categories.filter((data) => data.id === 17).length !== 0
            )}
            updated={() => {
              getAllProducts();
              getWishList();
              getCartData();
            }}
          />
        </section>
      ) : (
        <></>
      )}
      {/* section E ends */}
      {/* middle banner starts */}

      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1959.4229427552941!2d78.69752482961175!3d10.823103600000007!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3baaf5fafcc6d9ad%3A0xbb520b1b2a9a8147!2sravi%20cards!5e0!3m2!1sen!2sin!4v1660934996536!5m2!1sen!2sin"
        className="map_view"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
      {/* middle banner ends */}
      {/* footer */}
      <Footer />
    </>
  );
};
