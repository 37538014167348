import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Header } from "../../components";
import "./productPage.css";
import LargeMenu from "../../components/shortcutField/largeDevice/menu";
import MobileMenu from "../../components/shortcutField/mobile/menu";
import { PreloaderContext } from "../../context/preloader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartPlus,
  faHeart,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import "./productPage.css";
import {
  HandleAddToCart,
  HandleAddWishlist,
} from "../../components/carousel/HandlingFunc";
import { Link } from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Footer } from "../../components";
import { cartData, WishlistData } from "../../components/carousel/HandlingFunc";
import { loginContext } from "../../context/loginContext";
import Cookies from "js-cookie";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const ProductPage = () => {
  const { setloginModal } = useContext(loginContext);
  const { height, width } = useWindowDimensions();
  const { setPreloader } = useContext(PreloaderContext);
  let { product_id } = useParams();
  const [productDetails, setProductDetails] = useState();
  const [zoomImage, setZoomImage] = useState();
  const [cartBtn, setCartBtnStatus] = useState(false);
  const [wishListBtn, setWishListStatus] = useState(false);
  const [cartDataState, setCartData] = useState([]);
  const [wishlistDataState, setWishlistData] = useState(null);
  const getProductDetails = () => {
    setPreloader(true);
    axios
      .post(
        process.env.REACT_APP_URL + "getIndividualProduct",
        {
          prodId: product_id,
          access: Cookies.get("access"),
        },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((response) => {
        setProductDetails(response.data.mainData);
        response.data.cartStatus === 0 ? <></> : setCartBtnStatus(true);
        response.data.wishListStatus === 0 ? <></> : setWishListStatus(true);
        setZoomImage(response.data.mainData.images[0]["src"]);
        setPreloader(false);
      })
      .catch((err) => console.log(err));
  };

  // getting cart data for this client
  const getCartData = () => {
    setCartData([]);
    cartData().then((res) => {
      res.map((e) =>
        setCartData((cartDataState) => [
          ...cartDataState,
          { prod_id: e["prod_id"], quantity: e["quantity"] },
        ])
      );
    });
  };

  // get wishlist data to show wishlist products
  const getWishList = () => {
    WishlistData().then((res) => {
      setWishlistData(res);
    });
  };

  useEffect(() => {
    getProductDetails();
    getCartData();
    getWishList();
  }, []);
  return (
    <>
      <Header
        WishlistLength={
          wishlistDataState !== null && wishlistDataState !== undefined ? (
            wishlistDataState.length
          ) : (
            <></>
          )
        }
        cartLength={cartDataState !== null ? cartDataState.length : <></>}
      />
      {zoomImage === undefined ? (
        <></>
      ) : (
        <>
          <div className="container-fluid row">
            {parseInt(width) > 992 ? (
              <>
                {" "}
                <div className="col-md-2 col-sm-12">
                  <LargeMenu />{" "}
                </div>
              </>
            ) : (
              <>
                <MobileMenu />
              </>
            )}

            <div className="col-lg-10 col-md-12 col-sm-12">
              <div className="text-center m-3">
                <div
                  className="product-page-img"
                  style={{
                    backgroundImage: `url(${zoomImage})`,
                  }}
                />
                <ul class="list-group product_image_list justify-content-center m-4 list-group-horizontal">
                  {/* <li class="list-group-item">
                    <button
                      className="text-decoration-none m-0 border-0 bg-transparent"
                      onClick={(event) =>
                        setZoomImage(productDetails.images[0]["src"])
                      }
                    >
                      <img
                        src={productDetails.images[0]["src"]}
                        alt="product_image"
                        className="product_imageList"
                      />
                    </button>
                  </li> */}
                  {console.log("productDetails", productDetails)}
                  {productDetails.images.map((item) => (
                    <>
                      <li class="list-group-item">
                        <button
                          className="text-decoration-none m-0 border-0 bg-transparent"
                          onClick={(event) => setZoomImage(item.src)}
                        >
                          <img
                            src={item.src}
                            alt="product_image"
                            className="product_imageList"
                          />
                        </button>
                      </li>
                    </>
                  ))}
                </ul>
              </div>
              {/* Product details */}
              <div className="container-fluid p-3">
                <table className="product-info-table">
                  <tbody>
                    <tr>
                      <td>
                        <h5 className="text-start fw-bold mb-3 fs-5">
                          <span className="text-secondary me-2">
                            Product ID:
                          </span>
                        </h5>
                      </td>
                      <td>
                        <h5 className="text-start fw-bold mb-3 fs-5">
                          {" "}
                          {productDetails.id}
                        </h5>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h5 className="text-start fw-bold mb-3 fs-5">
                          <span className="text-secondary me-2">Name:</span>
                        </h5>
                      </td>
                      <td>
                        <h5 className="text-start fw-bold mb-3 fs-5">
                          {productDetails.name}
                        </h5>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h5 className="text-start fw-bold mb-3 fs-5">
                          <span className="text-secondary me-2 text-nowrap">
                            Product Dimension:
                          </span>
                        </h5>
                      </td>
                      <td>
                        <h5 className="text-start fw-bold mb-3 fs-5">
                          {productDetails.dimensions.height} X{" "}
                          {productDetails.dimensions.width}
                        </h5>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h5 className="text-start fw-bold mb-3 fs-5">
                          <span className="text-secondary me-2 text-nowrap">
                            About Product:
                          </span>
                        </h5>
                      </td>
                      <td>
                        <h5
                          className="text-start fw-bold mb-3 fs-5"
                          dangerouslySetInnerHTML={{
                            __html: productDetails.description,
                          }}
                        ></h5>
                      </td>
                    </tr>
                    <tr>
                      {/* Price section */}
                      <td>
                        <h5 className="text-start fw-bold mb-3 fs-5">
                          <span className="text-secondary me-2">Price:</span>
                        </h5>
                      </td>
                      <td>
                        <h5 className="text-start fw-bold mb-3 fs-5">
                          {productDetails.price !==
                          productDetails.regular_price ? (
                            <>
                              {productDetails.price}
                              <span className="text-danger">
                                {" "}
                                -
                                {Math.round(
                                  productDetails.regular_price -
                                    productDetails.regular_price *
                                      (productDetails.price / 100)
                                )}
                                % <small>You save</small>
                              </span>
                              <br />
                              <span className="text-muted fs-6">
                                M.R.P:{" "}
                                <s className="text-muted">
                                  ₹ {productDetails.regular_price}
                                </s>{" "}
                              </span>
                            </>
                          ) : (
                            <span>₹ {productDetails.price}</span>
                          )}
                        </h5>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h5 className="text-start fw-bold mb-3 fs-5">
                          <span className="text-secondary me-2 text-nowrap">
                            Available Stocks:
                          </span>
                        </h5>
                      </td>
                      <td>
                        <h5 className="text-start fw-bold mb-3 fs-5">
                          <span className="text-success">
                            {productDetails.sku}pcs.
                          </span>
                        </h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
                {/* stock */}
                <h5 className="text-start fw-bold">
                  {productDetails.sku === "0" ||
                  productDetails.stock_status !== "instock" ? (
                    <span className="text-danger  fs-3">* Out of stock *</span>
                  ) : (
                    <></>
                  )}
                </h5>
                <div className="container border border-muted bg-pattern py-3 d-flex my-5">
                  <span className="fs-5 text-light fw-bold text-center">
                    For More Details Call now at{" "}
                    <span className="ms-3"> +1234567890 </span>
                  </span>
                </div>
                <div className="d-flex justify-content-center text-nowrap">
                  {/* cart Btn */}
                  {productDetails.stock_status !== "instock" ? (
                    <></>
                  ) : cartBtn ? (
                    <Link
                      to="/cart"
                      className="text-decoration-none btn btn-sm p-3 btn-primary border"
                    >
                      View Cart
                    </Link>
                  ) : (
                    <button
                      className="text-decoration-none btn btn-sm rounded p-3 btn-primary border"
                      onClick={() =>
                        HandleAddToCart(productDetails.id.toString()).then(
                          (res) => {
                            if (res === "success") {
                              getProductDetails();
                            } else if (res === "login") setloginModal(true);
                          }
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faCartPlus} /> Add to cart
                    </button>
                  )}

                  {/* Enquiry bar */}

                  {/* Wishlist Btn */}
                  {wishListBtn ? (
                    <Link
                      to="/wishlist"
                      className="text-decoration-none btn btn-md p-3  ms-3 rounded btn-success text-white border"
                    >
                      View Wishlist
                    </Link>
                  ) : (
                    <button
                      className="text-decoration-none btn btn-md p-3 ms-3 rounded btn-success text-white border"
                      onClick={() =>
                        HandleAddWishlist(productDetails.id.toString()).then(
                          (res) => {
                            if (res === "success") {
                              getProductDetails();
                            } else if (res === "login") setloginModal(true);
                          }
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faHeart} /> Add to wishlist
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <Footer />
    </>
  );
};

export default ProductPage;
