import React, { useContext } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo1.png";
import "./header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faBars,
  faCartArrowDown,
  faHeart,
  faInbox,
  faMailBulk,
  faPhone,
  faSignInAlt,
} from "@fortawesome/free-solid-svg-icons";
import { loginContext } from "../../context/loginContext";
import Cookies from "js-cookie";

export const Header = (props) => {
  const { loginModal, setloginModal } = useContext(loginContext);

  return (
    <>
      <div className="bg-grey">
        <ul class="list-group list-group-horizontal justify-content-end me-2">
          <li class="list-group-item bg-transparent border-0 p-2 pe-3">
            <a
              href={`tel: ${process.env.REACT_APP_PHONE}`}
              className="text-dark text-decoration-none fs-6"
            >
              {" "}
              <FontAwesomeIcon icon={faPhone} /> {process.env.REACT_APP_PHONE}
            </a>
          </li>
          <li class="list-group-item bg-transparent border-0 p-2 pe-3">
            <a
              href={`mailto: ${process.env.REACT_APP_EMAIL}`}
              className="text-dark text-decoration-none fs-6"
            >
              <FontAwesomeIcon icon={faMailBulk} />{" "}
              {process.env.REACT_APP_EMAIL}
            </a>
          </li>
        </ul>
      </div>
      <nav className="navbar border navbar-expand-lg p-0 fs-6">
        <div className="container-fluid">
          <Link
            className="d-flex justify-content-center text-center align-items-center navbar-brand"
            to="/"
          >
            <img src={logo} alt="LOGO" />
            <p className="text-primaryBlue text-shadow fw-bold align-middle my-auto fs-2">
              Ravi Cards
            </p>
          </Link>
          <button
            className="navbar-toggler border border-dark shadow-none"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <FontAwesomeIcon icon={faBars} className="p-1 fs-3" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto pe-5 mb-2 mb-lg-0">
              <li className="nav-item m-3">
                <Link
                  to="/"
                  className="text-decoration-none text-dark fw-bolder px-2"
                >
                  Home
                </Link>
              </li>
              <li className="nav-item m-3">
                <Link
                  to="/about"
                  className="text-decoration-none text-dark fw-bolder px-2"
                >
                  About Us
                </Link>
              </li>
              <li className="nav-item m-3">
                <Link
                  to="/contact"
                  className="text-decoration-none text-dark fw-bolder px-2"
                >
                  Contact Us
                </Link>
              </li>
              <li className="nav-item m-3">
                <div class="dropdown">
                  <button
                    class="btn  bg-transparent border-0 p-0 px-1 fw-bolder shadow-none dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Our products
                  </button>
                  <ul
                    class="dropdown-menu border-primary mt-3"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li className="p-2">
                      <Link
                        class="dropdown-item fw-bold"
                        to="/product-list/all"
                        onClick={() => {
                          props.updateRoute("all");
                          document
                            .getElementById("dropdownMenuButton1")
                            .click();
                        }}
                      >
                        All Cards
                      </Link>
                    </li>
                    <li className="p-2">
                      <Link
                        class="dropdown-item fw-bold"
                        to="/product-list/18"
                        onClick={() => {
                          props.updateRoute("18");
                          document
                            .getElementById("dropdownMenuButton1")
                            .click();
                        }}
                      >
                        Marriage Cards
                      </Link>
                    </li>
                    <li className="p-2">
                      <Link
                        class="dropdown-item fw-bold"
                        to="/product-list/23"
                        onClick={() => {
                          props.updateRoute("23");
                          document
                            .getElementById("dropdownMenuButton1")
                            .click();
                        }}
                      >
                        Occasion Cards
                      </Link>
                    </li>
                    <li className="p-2">
                      <Link
                        class="dropdown-item fw-bold"
                        to="/product-list/37"
                        onClick={() => {
                          props.updateRoute("37");
                          document
                            .getElementById("dropdownMenuButton1")
                            .click();
                        }}
                      >
                        Home made chocolates
                      </Link>
                    </li>
                    <li className="p-2">
                      <Link
                        class="dropdown-item fw-bold"
                        to="/product-list/24"
                        onClick={() => {
                          props.updateRoute("24");
                          document
                            .getElementById("dropdownMenuButton1")
                            .click();
                        }}
                      >
                        Others
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="nav-item m-2 d-flex">
                <Link to="/cart" className="btn btn-lg btn-warning">
                  <FontAwesomeIcon icon={faCartArrowDown} />{" "}
                  {props.cartLength === undefined ? <></> : props.cartLength}
                </Link>
                <Link to="/wishlist" className="btn btn-lg ms-3 btn-danger">
                  <FontAwesomeIcon icon={faHeart} />{" "}
                  {props.WishlistLength === undefined ? (
                    <></>
                  ) : (
                    props.WishlistLength
                  )}
                </Link>
                {Cookies.get("access") !== undefined &&
                Cookies.get("access") !== "" ? (
                  <button
                    className="btn btn-lg btn-outline-primary ms-3"
                    onClick={() => {
                      Cookies.remove("access");
                      window.location.reload();
                    }}
                  >
                    <FontAwesomeIcon icon={faArrowRightFromBracket} />
                  </button>
                ) : (
                  <button
                    className="btn btn-lg btn-outline-primary ms-3"
                    onClick={() => setloginModal(true)}
                  >
                    <FontAwesomeIcon icon={faSignInAlt} />
                  </button>
                )}
              </li>
              {/* <li className="nav-item m-2"></li>
              <li className="nav-item m-2">
               
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};
