import React, { useState } from "react";
import { Link } from "react-router-dom";
import { btnList } from "../../array/homeBtnList";
import { Header, Footer } from "../../components";
export const About = () => {
  const [activeButton, setActiveButton] = useState();
  const HandleCatClick = (event) => {
    setActiveButton(event.target.id);
  };
  return (
    <>
      <Header />
      <section className="container">
        <h1 className="text-center fs-1 pt-4 text-dark fw-bold">
          About <span className="text-primary">Ravi Cards</span>
        </h1>
        <div className="p-4">
          <p className="fs-5 lh-lg pb-4 text-justify">
            Established in 1960, Ravi cards is a leading Wholesale and Retailer
            of Invitation cards in Trichy. My shop was started by my
            grandfather, Mr.C.Senan Chettiar, and followed by Mr. Chandra
            Mouliswaran My father, and now successfully inherited by myself, C.
            Chandra Ghosh and we are proud to say, We are the only ready
            stockist of wedding cards with a multi storied building in Trichy.
            The quality of card conveys the magnifiance of your wedding. A mere
            look at it, will convey the grandeur of the celebration. There is
            nothing more auspicious to start your wedding journey, Than our
            authentic cards. Because, Best design and quality cards at lowest
            price is our motto
          </p>
          <p className="fs-5 lh-lg pb-4 text-justify">
            We have a wide range of collection on the basis of, Readymade,
            Exclusive and Customised cards to satisfy all customers, Ravi cards
            has more than 1000 varieties of cards to cater all family, functions
            and ceremonies including, Wedding, Betrothal, Grahapravesam,
            Birthday, Puberty and what not.
          </p>
          <p className="fs-5 lh-lg text-justify">
            Why run for wedding return gifts and wedding invitations separately,
            When we have a 2 in 1 beautiful solution for you. Yes, It's our,
            customised home made chocolate invitations cum return gifts. Tongue
            tickling flavours of chocolates, all home made with gentle care and
            curiosity. To make your weddings more sweeter, We offer{" "}
            <span className="text-primary fw-bold">
              chocolate bouquets, chocolate garlands , return gift boxes,
              assorted chocolate boxes,
            </span>{" "}
            and{" "}
            <span className="text-primary fw-bold">
              customised wrappers on chocolate slabs,{" "}
            </span>
            and even <span className="text-primary fw-bold">pinnatas</span>, and
            finally to add flavour, we have
            <span className="text-primary fw-bold"> paan chocolates</span>
          </p>
        </div>
      </section>
      {/* Category btn starts */}
      <section className="container-fluid justify-content-evenly my-4 py-4 px-2 row">
        {btnList.map((data, index) => (
          <Link
            to={"/product-list/" + data.value}
            id={index}
            className={`btn btn-lg col-md-3 col-sm-6 CatBtn m-3 py-3 text-nowrap ${
              activeButton === index ? "btnActive" : null
            }`}
            value={data.value}
            onClick={(event) => HandleCatClick(event)}
          >
            {data.name}
          </Link>
        ))}
      </section>
      {/* Category btsn ends */}

      <Footer />
    </>
  );
};
