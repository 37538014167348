import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { SortByPriceHighToLow, SortByPriceLowToHigh } from "./sortingFunctions";

const SortOptions = ({ TotalData, btnClassName, ...props }) => {
  const [lowtohight, setLowtohigh] = useState(true);
  return (
    <>
      <button
        className={`btn btn-md shadow-none bg-transparent ${btnClassName}`}
        onClick={() => {
          props.resultData(
            lowtohight
              ? SortByPriceLowToHigh(TotalData)
              : SortByPriceHighToLow(TotalData)
          );
          setLowtohigh(!lowtohight);
        }}
      >
        <p className="text-muted m-0">Price: <span className="text-dark">{lowtohight ? <>Low to High <FontAwesomeIcon icon={faArrowDown} /></> : <>High to Low <FontAwesomeIcon icon={faArrowUp} /></>}</span></p>
      </button>
    </>
  );
};

export default SortOptions;
