import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Header } from "../../components";
import {
  HandleRemoveWishlist,
  HandleAddWishlist,
} from "../../components/carousel/HandlingFunc";
import {
  faArrowRight,
  faBars,
  faCheckCircle,
  faHeart as faHeartBold,
  faPhone,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faHeart as faHeartReg } from "@fortawesome/free-regular-svg-icons";
import CartBtn from "../../components/cartBtn/cartBtn";
import "./cart.css";
import { Link } from "react-router-dom";
import { PreloaderContext } from "../../context/preloader";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { loginContext } from "../../context/loginContext";
import Cookies from "js-cookie";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const Cart = () => {
  const { setloginModal } = useContext(loginContext);
  const { width } = useWindowDimensions();
  const { setPreloader } = useContext(PreloaderContext);
  const [cartProducts, setCartProducts] = useState(null);
  const [wishlistData, setWishlistData] = useState(null);
  const [totalProducts, setTotalProducts] = useState(0);
  const [totalPieces, setTotalPieces] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [displayList, setdisplayList] = useState(false);
  const [checkoutData, setCheckoutData] = useState([]);
  const gst = 500;
  // get wishlist data to show wishlist products
  const getWishList = () => {
    axios
      .post(
        process.env.REACT_APP_URL + "getWishlistData",
        {
          access: Cookies.get("access"),
        },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((response) => {
        setWishlistData(response.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const totalPieceFunc = (data) => {
    var totalPieceTemp = 0;
    data.map((item) =>
      setTotalPieces(
        (totalPieceTemp = parseInt(totalPieceTemp) + parseInt(item.quantity))
      )
    );
  };
  const subTotalFunc = (data) => {
    var subTotalTemp = 0;
    data.map((data) =>
      setSubTotal(
        (subTotalTemp = parseInt(subTotalTemp) + data.price * data.quantity)
      )
    );
  };

  const AddCheckOutData = (data) => {
    data.map((item, index) => {
      setCheckoutData({
        ...checkoutData,
        [index]: {
          ["Product ID"]: item.id,
          ["Product Name"]: item.name,
          ["Quantity"]: item.quantity,
          ["Price"]: item.price,
        },
      });
    });
  };
  // get  cart products for this customer
  const getCartProducts = () => {
    setPreloader(true);
    axios
      .post(
        process.env.REACT_APP_URL + "getCartWithProd",
        {
          access: Cookies.get("access"),
        },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((response) => {
        if (response.data.status === 200) {
          //Add data to checkout
          AddCheckOutData(response.data.message);
          // state to display cart products
          setCartProducts(response.data.message);
          // state to display total product count
          setTotalProducts(response.data.message.length);
          // state to display total pieces selected by customer
          totalPieceFunc(response.data.message);
          // state to display subtotal of the selected
          subTotalFunc(response.data.message);
          setPreloader(false);
        } else if (response.data.status === 401) {
          setPreloader(false);
          setloginModal(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // delete cart products in database
  const deleteCart = (prod_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to remove this product from Cart!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, remove it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(
            process.env.REACT_APP_URL + "removeFromCart",
            {
              prodId: prod_id,
              access: Cookies.get("access"),
            },
            { headers: { "Content-Type": "application/json" } }
          )
          .then((response) => {
            response.data.status === 200 ? getCartProducts() : <></>;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };
  useEffect(() => {
    getCartProducts();
    getWishList();
  }, []);
  return (
    <>
      <Header
        WishlistLength={
          wishlistData !== null && wishlistData !== undefined
            ? wishlistData.length
            : 0
        }
        cartLength={cartProducts !== null ? cartProducts.length : 0}
      />
      <div className="d-flex justify-content-between bg-primaryBlue">
        <h1 className="text-center fw-bold border-0 p-3 text-gold  my-3">
          Your Cart
        </h1>
        <div className="m-4">
          <Link
            to="/product-list/all"
            className="btn text-decoration-none btn-md p-2 btn-outline-secondary text-nowrap text-white"
          >
            Continue Shopping &nbsp;
            <FontAwesomeIcon icon={faArrowRight} />
          </Link>
        </div>
      </div>
      <div
        className="container-fluid row justify-content-start cart_list"
        style={{ float: "center" }}
      >
        {cartProducts !== null && cartProducts.length > 0 ? (
          cartProducts.map((item) => (
            <>
              <div className="col-lg-4 col-md-4 col-sm-12 mt-3">
                <div className="card m-3 mb-4 product_image_card">
                  {wishlistData !== null &&
                  wishlistData !== undefined &&
                  wishlistData.filter(
                    (data) => data.prod_id === item.id.toString()
                  )[0] ? (
                    <button
                      onClick={() => {
                        Swal.fire({
                          title: "Are you sure?",
                          text: "You want to remove this product from wishlist!",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          confirmButtonText: "Yes, remove it!",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            HandleRemoveWishlist(item.id).then((res) => {
                              res === "success" ? getWishList() : <></>;
                            });
                          }
                        });
                      }}
                      className="wishlist_btn rounded"
                    >
                      <FontAwesomeIcon
                        icon={faHeartBold}
                        className="heartIcon fs-5"
                      />
                    </button>
                  ) : (
                    <>
                      <button
                        onClick={() => {
                          HandleAddWishlist(item.id).then((res) => {
                            res === "success" ? getWishList() : <></>;
                          });
                        }}
                        className="wishlist_btn rounded"
                      >
                        <FontAwesomeIcon
                          icon={faHeartReg}
                          className="heartIcon fs-5"
                        />
                      </button>
                    </>
                  )}

                  <button
                    onClick={() => {
                      deleteCart(item.id);
                    }}
                    className="trash_btn rounded"
                  >
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      className="text-danger fs-5"
                    />
                  </button>
                  <Link
                    to={"/product-page/" + item.id}
                    className="text-decoration-none text-center text-dark"
                  >
                    <div
                      className="product-card-img"
                      style={{
                        backgroundImage: `url(${item.images?.[0]?.["src"]})`,
                      }}
                    />
                  </Link>
                  <div className="card-body">
                    {/* <h5 class="card-title">Card title</h5> */}
                    <Link
                      to={"/product-page/" + item.id}
                      className="text-decoration-none text-dark"
                    >
                      <h6 className="card-text mt-3">
                        <p>
                          <span className="fw-bold">
                            Product Code : {item.id}
                          </span>
                        </p>
                      </h6>
                    </Link>
                    <div className="d-flex">
                      {item.price !== item.regular_price ? (
                        <>
                          <p className="fw-bold price_text">
                            ₹ <span className="fs-5">{item.price} </span>{" "}
                            <span className="text-danger">
                              -{" "}
                              {Math.round(
                                item.regular_price -
                                  item.regular_price * (item.price / 100)
                              )}
                              % <small>You save</small>
                            </span>
                            <br />
                            <span className="text-muted">
                              <s>M.R.P. {item.regular_price}</s>
                            </span>
                          </p>
                        </>
                      ) : (
                        <p className="fw-bold price_text">
                          ₹ <span className="fs-5">{item.price}</span>
                        </p>
                      )}

                      {/* <Link to="/cart" className="ms-auto"> */}
                      <span className="ms-auto">
                        <CartBtn
                          data={{
                            prod_id: item.id,
                            quantity: item.quantity,
                          }}
                          updatedData={() => getCartProducts()}
                        />
                      </span>
                      {/* </Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))
        ) : (
          <>
            <h3 className="container-fluid text-center text-dark my-5">
              No products in the cart{" "}
              <Link to="/" className="text-decoration-none text-gold">
                Continue shopping
              </Link>
            </h3>
          </>
        )}
      </div>
      {parseInt(width) > 900 ? (
        <div className="d-flex border p-3 bg-light  cart_summary justify-content-around">
          <p className="value_title fw-bold mx-2 text-muted">
            Total Products <br />
            <span className="value_attr text-dark fw-bold">count. </span>
            <span className="text-dark fw-bold">{totalProducts}</span>
          </p>
          <p className="value_title fw-bold mx-2 text-muted">
            Total Pieces <br />{" "}
            <span className="value_attr text-dark fw-bold">Qty. </span>
            <span className="text-dark fw-bold">{totalPieces}</span>
          </p>
          <p className="value_title fw-bold mx-2 text-muted">
            Sub total <br />{" "}
            <span className="value_attr text-dark fw-bold">Rs. </span>
            <span className="text-dark fw-bold">{subTotal}</span>
          </p>
          <p className="value_title fw-bold mx-2 text-muted">
            GST in <br />{" "}
            <span className="value_attr text-dark fw-bold">Rs. </span>
            <span className="text-dark fw-bold">{gst}</span>
          </p>
          <p className="value_title fw-bold mx-2 text-muted">
            Total Price <br />{" "}
            <span className="value_attr text-dark fw-bold">Rs. </span>
            <span className="text-dark fw-bold">
              {parseInt(gst) + parseInt(subTotal)}
            </span>
          </p>
          <a
            href={`https://api.whatsapp.com/send?phone=${
              process.env.REACT_APP_PHONE
            }&text=Hi%20There! I need the following products,Product Details: ${JSON.stringify(
              checkoutData
            )}, Order Summary: Total Piece: ${totalPieces}, Total Products: ${totalProducts}, Subtotal: ${subTotal}, Tax: ${gst}, Total Price: ${
              parseInt(gst) + parseInt(subTotal)
            }`}
            className="btn text-decoration-none btn-lg px-5 bg-gold  my-3 fw-bold"
          >
            <FontAwesomeIcon icon={faCheckCircle} /> &nbsp;Checkout
          </a>
        </div>
      ) : (
        <>
          <ul className="list-group bg-primaryBlue cart_summary">
            <li class="list-group-item w-100 float-start border-0 bg-primaryBlue">
              <button
                className="btn text-gold btn-sm bg-transparent border"
                onClick={() => setdisplayList(!displayList)}
              >
                <FontAwesomeIcon icon={faBars} className="p-1 fs-3" />
              </button>

              <ul
                class={`list-group cart_list_mobile ${
                  displayList ? "d-block" : "d-none"
                }`}
              >
                <li class="list-group-item">
                  <span className="mx-2 fs-5 text-primaryBlue fw-bold">
                    Total Products:{" "}
                  </span>
                  <span className="text-dark fw-bold">
                    {totalProducts} <small>count.</small>
                  </span>
                </li>
                <li class="list-group-item">
                  {" "}
                  <span className="mx-2 fs-5 text-primaryBlue fw-bold">
                    Total Pieces:{" "}
                  </span>{" "}
                  <span className="text-dark fw-bold">
                    {totalPieces} <small>Qty.</small>
                  </span>
                </li>
                <li class="list-group-item">
                  <span className="mx-2 fs-5 text-primaryBlue fw-bold">
                    Sub total:{" "}
                  </span>
                  <span className="text-dark fw-bold">
                    {subTotal} <small>Rs.</small>
                  </span>
                </li>
                <li class="list-group-item">
                  <span className="mx-2 fs-5 text-primaryBlue fw-bold">
                    GST in:{" "}
                  </span>
                  <span className="text-dark fw-bold">
                    {gst} <small>Rs. </small>
                  </span>
                </li>
                <li class="list-group-item">
                  <span className="mx-2 fs-5 text-primaryBlue fw-bold">
                    Total Price:{" "}
                  </span>
                  <span className="text-dark fw-bold">
                    {parseInt(gst) + parseInt(subTotal)} <small>Rs. </small>
                  </span>
                </li>
                <li class="list-group-item text-center">
                  <a
                    target="_blank"
                    href={`https://api.whatsapp.com/send?phone=${
                      process.env.REACT_APP_PHONE
                    }&text=Hi%20There! I need the following products,Product Details: ${JSON.stringify(
                      checkoutData
                    )}, Order Summary: Total Piece: ${totalPieces}, Total Products: ${totalProducts}, Subtotal: ${subTotal}, Tax: ${gst}, Total Price: ${
                      parseInt(gst) + parseInt(subTotal)
                    }`}
                    className="btn text-decoration-none btn-lg px-5 bg-gold  my-3 fw-bold"
                  >
                    <FontAwesomeIcon icon={faCheckCircle} /> &nbsp;Checkout
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </>
      )}
    </>
  );
};

export default Cart;
